<template id="dashboard">
  <b-card
    class="m-1 h-100"
    :style="
      da_confrontare && confronta
        ? 'background: #606060'
        : player && player.ruolo_id == 6
        ? 'background: #333333'
        : ''
    "
  >
    <span
      v-if="confronta"
      @click="editConfronto(player)"
      style="
        cursor: pointer;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
      "
    >
    </span>
    <b-row align-v="center">
      <b-col v-if="auth.isUserEnable('opta')" cols="1" class="p-0">
        <div
          v-if="editable && !in_lista && player.giocatore"
          class="plus_round"
          style="
            position: absolute;
            top: 0px;
            left: 40px;
            cursor: pointer;
            z-index: 1;
          "
          @click="addGiocatoreLista(player.giocatore.id)"
        >
          <b-icon icon="plus" scale="2"></b-icon>
        </div>
        <div
          v-if="editable && in_lista && player.giocatore"
          class="minus_round"
          style="
            position: absolute;
            bottom: 0px;
            left: 40px;
            cursor: pointer;
            z-index: 1;
          "
          @click="delGiocatoreLista(player.giocatore.id)"
        >
          <b-icon icon="dash" scale="2"></b-icon>
        </div>
        <b-row>
          <b-col
            v-for="(skill, index) in standard_skills"
            cols="12"
            :class="getClassByIndex(index)"
            :key="'st_' + skill.id"
          >
            <div
              class="square_stats"
              :style="
                'background:' +
                getBgStat(skill.id, getValueStat(skill.id)) +
                ';' +
                (getValueStat(skill.id).length < 4 ? 'font-size: .8rem;' : '')
              "
              :title="skill.label"
            >
              {{ getValueStat(skill.id) }}
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-else cols="1" class="p-0">
        <div
          v-if="editable && !in_lista && player.giocatore"
          class="plus_round"
          style="cursor: pointer; z-index: 1; padding-top: 1px"
          @click="addGiocatoreLista(player.giocatore.id)"
        >
          <b-icon icon="plus" scale="2"></b-icon>
        </div>
        <div
          v-if="editable && in_lista && player.giocatore"
          class="minus_round"
          style="cursor: pointer; z-index: 1; padding-top: 1px"
          @click="delGiocatoreLista(player.giocatore.id)"
        >
          <b-icon icon="dash" scale="2"></b-icon>
        </div>
      </b-col>
      <b-col
        class="p-0"
        align-self="center"
        :cols="auth.isUserEnable('opta') ? '6' : '5'"
      >
        <polar-player
          :key="player.id + '_' + $store.state.extraValueIndex"
          v-if="auth.isUserEnable('opta')"
          :player_id="player.id"
          :player_avatar="
            player.giocatore
              ? player.giocatore.avatar_url
              : '/assets/images/profile.png'
          "
          :player_data="player_data"
          :width="230"
          :height="230"
        />
        <router-link
          v-else
          :to="{
            name: 'ScoutingEvalPlayer',
            params: { id: player.giocatore.id },
          }"
          target="_blank"
          style="cursor: pointer"
        >
          <avatar
            :avatar="'' + player.giocatore.avatar_url"
            :id="'opta' + player.giocatore.id"
          />
        </router-link>
      </b-col>
      <b-col :cols="auth.isUserEnable('opta') ? '5' : '6'">
        <b-popover
          v-if="
            player.giocatore &&
            player.giocatore.osservato &&
            player.giocatore.osservato.valutazioni.length > 0
          "
          :target="'popover-target-' + player.id"
          triggers="hover"
          placement="top"
        >
          <b-row
            align-v="center"
            v-for="val in player.giocatore.osservato.valutazioni"
            :key="val.id"
          >
            <b-col>
              {{ val.created_at | formatDate }}
            </b-col>
            <b-col v-if="auth.isAdmin() || auth.isOsservCapo()">
              {{ val.utente.cognome }}
            </b-col>
            <b-col>
              <div class="py-1">
                <grad1 class="grad" v-if="val.gradimento_id == 1" />
                <grad2 class="grad" v-if="val.gradimento_id == 2" />
                <grad3 class="grad" v-if="val.gradimento_id == 3" />
                <grad4 class="grad" v-if="val.gradimento_id == 4" />
                <grad5 class="grad" v-if="val.gradimento_id == 5" />
              </div>
            </b-col>
          </b-row>
        </b-popover>
        <div
          :id="'popover-target-' + player.id"
          v-if="auth.isUserEnable('opta')"
        >
          <b-row>
            <b-col class="p-0 text-center"><grad1 class="grad" /></b-col>
            <b-col class="p-0 text-center"><grad2 class="grad" /></b-col>
            <b-col class="p-0 text-center"><grad3 class="grad" /></b-col>
            <b-col class="p-0 text-center"><grad4 class="grad" /></b-col>
            <b-col class="p-0 text-center"><grad5 class="grad" /></b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col class="p-0 text-center"
              ><b-badge variant="bg-1" :style="grad1_style">{{
                grad1_count
              }}</b-badge>
            </b-col>
            <b-col class="p-0 text-center"
              ><b-badge variant="bg-2" :style="grad2_style">{{
                grad2_count
              }}</b-badge>
            </b-col>
            <b-col class="p-0 text-center"
              ><b-badge variant="bg-3" :style="grad3_style">{{
                grad3_count
              }}</b-badge></b-col
            >
            <b-col class="p-0 text-center"
              ><b-badge variant="bg-4" :style="grad4_style">{{
                grad4_count
              }}</b-badge></b-col
            >
            <b-col class="p-0 text-center"
              ><b-badge variant="bg-5" :style="grad5_style">{{
                grad5_count
              }}</b-badge></b-col
            >
          </b-row>
        </div>
        <b-row class="mt-1 mb-1">
          <b-col cols="12" style="font-size: 0.8rem; font-weight: bold">
            <div
              :title="
                player.giocatore
                  ? player.giocatore.cognome + ' ' + player.giocatore.nome
                  : player.lastName + ' ' + player.firstName
              "
              style="
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{
                player.giocatore ? player.giocatore.cognome : player.lastName
              }}
              {{ player.giocatore ? player.giocatore.nome : player.firstName }}
            </div>
          </b-col>
          <b-col cols="12" style="font-size: 0.75rem; min-height: 18px"
            >{{
              player.giocatore
                ? player.giocatore.data_nascita
                : player.birthDate | year
            }}
            ({{
              player.giocatore
                ? player.giocatore.data_nascita
                : player.birthDate | age
            }})</b-col
          >
          <b-col cols="12" style="font-size: 0.75rem; min-height: 18px">
            {{ ruolo_ideale ? ruolo_ideale.ruolo : "" }}
          </b-col>
          <b-col
            v-if="ruolo_secondario"
            cols="12"
            style="font-size: 0.75rem; min-height: 18px"
          >
            {{ ruolo_secondario.ruolo }}
          </b-col>
          <b-col cols="12" style="font-size: 0.75rem; min-height: 18px">{{
            player.team
              ? player.team.name
              : player.giocatore && player.giocatore.squadra_militante
              ? player.giocatore.squadra_militante.name
              : ""
          }}</b-col>
          <b-col cols="12">
            <flag
              :country="
                player.giocatore && player.giocatore.paese_nascita
                  ? player.giocatore.paese_nascita.sigla
                  : ''
              "
              :size="16"
              class="mr-2"
              title="Country of birth"
            ></flag>
            <flag
              :country="
                player.giocatore &&
                player.giocatore.passaporti &&
                player.giocatore.passaporti.length > 0
                  ? player.giocatore.passaporti[0].sigla
                  : ''
              "
              :size="16"
              title="Passport"
            ></flag
          ></b-col>
        </b-row>
        <b-row align-v="end" v-if="auth.isUserEnable('opta')">
          <b-col
            cols="5"
            class="p-0"
            @click="getRankIndexMacro"
            style="cursor: pointer"
          >
            <Progress
              v-if="!$store.state.extraValueIndex"
              :class="
                player.optaIndex && player.optaIndex.minutes < 600
                  ? 'macro_red'
                  : ''
              "
              title="macro"
              :strokeColor="
                player.optaIndex
                  ? bg_opta[player.optaIndex.macro_index_color_rank]
                  : '#FFFFFF'
              "
              :value="
                player.optaIndex
                  ? Math.round(player.optaIndex.macro_index * 100) / 100
                  : 0
              "
              :radius="25"
              :strokeWidth="4"
              :transitionDuration="0"
            >
            </Progress>
            <Progress
              v-else
              :class="
                player.optaExtraValueIndex &&
                player.optaExtraValueIndex.minutes < 600
                  ? 'macro_red'
                  : ''
              "
              title="macro"
              :strokeColor="
                player.optaExtraValueIndex
                  ? bg_opta[player.optaExtraValueIndex.macro_index_color_rank]
                  : '#FFFFFF'
              "
              :value="
                player.optaExtraValueIndex
                  ? Math.round(player.optaExtraValueIndex.macro_index * 100) /
                    100
                  : 0
              "
              :radius="25"
              :strokeWidth="4"
              :transitionDuration="0"
            >
            </Progress>
          </b-col>
          <b-col
            cols="7"
            class="p-0"
            @click="getRankIndexMicro"
            style="cursor: pointer"
          >
            <Progress
              v-if="!$store.state.extraValueIndex"
              title="micro"
              :class="
                player.optaIndex && player.optaIndex.minutes < 600
                  ? 'micro2_red'
                  : 'micro2'
              "
              :strokeColor="
                player.optaIndex
                  ? bg_opta[player.optaIndex.micro_index_color_rank]
                  : '#FFFFFF'
              "
              :value="
                player.optaIndex
                  ? Math.round(player.optaIndex.micro_index * 100) / 100
                  : 0
              "
              :radius="45"
              :strokeWidth="7"
              :transitionDuration="0"
            >
            </Progress>
            <Progress
              v-else
              title="micro"
              :class="
                player.optaExtraValueIndex &&
                player.optaExtraValueIndex.minutes < 600
                  ? 'micro2_red'
                  : 'micro2'
              "
              :strokeColor="
                player.optaExtraValueIndex
                  ? bg_opta[player.optaExtraValueIndex.micro_index_color_rank]
                  : '#FFFFFF'
              "
              :value="
                player.optaExtraValueIndex
                  ? Math.round(player.optaExtraValueIndex.micro_index * 100) /
                    100
                  : 0
              "
              :radius="45"
              :strokeWidth="7"
              :transitionDuration="0"
            >
            </Progress>
          </b-col>
        </b-row>
        <b-modal
          :ref="'rankIndexMacroModal' + player.id"
          :title="'Players index ranking: ' + title_index_rank"
          ok-only
          ok-title="Close"
        >
          <b-row
            align-v="center"
            v-for="(item, index) in players_index_rank"
            :key="index"
            class="p-1"
          >
            <b-col
              cols="1"
              class="text-center"
              :style="player.id == item.player.id ? 'color:#FF0000' : ''"
            >
              {{ index + 1 }}
            </b-col>
            <b-col cols="2" class="text-center">
              <b-img
                :src="
                  item.player && item.player.giocatore
                    ? item.player.giocatore.avatar_url
                    : '/assets/images/profile.png'
                "
                height="30"
              ></b-img
            ></b-col>
            <b-col :style="player.id == item.player.id ? 'color:#FF0000' : ''">
              <router-link
                :to="{
                  name: 'ScoutingViewPlayer',
                  params: { id: item.player.id },
                }"
                target="_blank"
                :style="
                  'cursor: pointer;' +
                  (player.id == item.player.id ? 'color:#FF0000' : '')
                "
                >{{
                  item.player.giocatore
                    ? item.player.giocatore.cognome
                    : item.player.lastName
                }}
              </router-link>
            </b-col>
            <b-col cols="3">
              <div
                :style="
                  'color:#000000;background:' +
                  bg_opta[item.macro_index_color_rank]
                "
                class="text-center"
              >
                <strong>{{ item.macro_index }}</strong>
              </div></b-col
            >
          </b-row>
        </b-modal>

        <b-modal
          :ref="'rankIndexMicroModal' + player.id"
          :title="'Players index ranking: ' + title_index_rank"
          ok-only
          ok-title="Close"
        >
          <b-row
            align-v="center"
            v-for="(item, index) in players_index_rank"
            :key="index"
            class="p-1"
          >
            <b-col
              cols="1"
              class="text-center"
              :style="player.id == item.player.id ? 'color:#FF0000' : ''"
            >
              {{ index + 1 }}
            </b-col>
            <b-col cols="2" class="text-center">
              <b-img
                :src="
                  item.player && item.player.giocatore
                    ? item.player.giocatore.avatar_url
                    : '/assets/images/profile.png'
                "
                height="30"
              ></b-img
            ></b-col>
            <b-col :style="player.id == item.player.id ? 'color:#FF0000' : ''">
              <router-link
                :to="{
                  name: 'ScoutingViewPlayer',
                  params: { id: item.player.id },
                }"
                target="_blank"
                :style="
                  'cursor: pointer;' +
                  (player.id == item.player.id ? 'color:#FF0000' : '')
                "
                >{{
                  item.player.giocatore
                    ? item.player.giocatore.cognome
                    : item.player.lastName
                }}
              </router-link>
            </b-col>
            <b-col cols="3">
              <div
                :style="
                  'color:#000000;background:' +
                  bg_opta[item.micro_index_color_rank]
                "
                class="text-center"
              >
                <strong>{{ item.micro_index }}</strong>
              </div></b-col
            >
          </b-row>
        </b-modal>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
import Progress from "easy-circular-progress";
import PolarPlayer from "@/components/charts/PolarPlayer.vue";
import Avatar from "@/components/Avatar.vue";
import Flag from "@/components/Flag.vue";
import grad1 from "@/assets/images/grad1.svg";
import grad2 from "@/assets/images/grad2.svg";
import grad3 from "@/assets/images/grad3.svg";
import grad4 from "@/assets/images/grad4.svg";
import grad5 from "@/assets/images/grad5.svg";
export default {
  props: {
    player: {
      type: Object,
      required: true,
    },
    skills: {
      type: Array,
      required: true,
    },
    ruolo: {
      type: Object,
      default: null,
    },
    in_lista: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    confronta: {
      type: Boolean,
      default: false,
    },
    da_confrontare: {
      type: Boolean,
      required: false,
    },
  },

  data: function () {
    return {
      fasi: [1, 3, 2, 4],
      fasi_label: ["transition +", "offensive", "transition -", "defensive"],
      fasi_items: [5, 5, 5, 5],
      bg_opta: [
        "#00B386",
        "#2EC85B",
        "#7BDE3C",
        "#B8F100",
        "#E9F700",
        "#FFF300",
        "#FFDB00",
        "#FFB800",
        "#FF8900",
        "#FF0059",
      ],
      opacity_value: [1, 0.8, 0.7, 0.6, 0.5],
      principles_title: [
        "PRESSING",
        "RECOVERY",
        "MARKING",
        "BUILD UP",
        "POSSESSION",
        "ENGAGEMENT",
        "UNMARKING",
        "DRIBBLING",
        "WIDE PLAY",
        "FINISHING",
        "CREATIVITY",
        "GOALKEEPER",
        "SET PLAY",
      ],
      principles_order: [9, 12, 10, 8, 1, 6, 3, 7, 2, 5, 4, 11, 11],
      players_index_rank: [],
      title_index_rank: null,
    };
  },

  components: {
    PolarPlayer,
    Avatar,
    Progress,
    grad1,
    grad2,
    grad3,
    grad4,
    grad5,
    Flag,
  },

  created: function () {},

  computed: {
    auth: function () {
      return Auth;
    },

    user: function () {
      return Auth.user;
    },

    ruolo_ideale: function () {
      return this.player.ruolo ? this.player.ruolo : null;
    },

    ruolo_secondario: function () {
      return this.player.ruolo2 ? this.player.ruolo2 : null;
    },

    standard_skills: function () {
      return this.skills.filter((item) => item.standard === 1);
    },

    skills_ruolo: function () {
      var skills_ruolo = [];
      var order = 1;
      for (var x in this.fasi) {
        var items = [];
        if (this.ruolo) {
          for (var i in this.ruolo["opta_skills_fase" + this.fasi[x]]) {
            var skill = this.ruolo["opta_skills_fase" + this.fasi[x]][i];
            var item = {
              id: skill.id,
              order: order,
              weight: 1,
              label: skill.label,
              sigla: skill.sigla ? skill.sigla : skill.name,
            };
            items.push(item);
            order++;
          }
        }
        var fase = {
          id: "fase" + this.fasi[x],
          label: this.fasi_label[x],
          order: parseInt(x) + 1,
          weight: this.fasi_items[x],
          items: items,
        };
        skills_ruolo.push(fase);
      }
      return skills_ruolo;
    },

    player_data: function () {
      var data = [];
      if (!this.$store.state.extraValueIndex) {
        if (this.player.optaIndex) {
          var principles_index = JSON.parse(
            this.player.optaIndex.principles_index
          );
          var principles_index_color_rank = JSON.parse(
            this.player.optaIndex.principles_index_color_rank
          );
          for (var i in principles_index) {
            if (
              (this.player.ruolo_id == 6 && i != 12) ||
              (this.player.ruolo_id != 6 && i != 11)
            ) {
              var value = {
                id: this.player.id + "_" + i,
                order: this.principles_order[i],
                score: principles_index[i],
                value: principles_index[i],
                weight: 1,
                color: this.bg_opta[principles_index_color_rank[i]],
                label: this.principles_title[i],
                sigla: this.principles_title[i].substr(0, 3),
              };
              data.push(value);
            }
          }
        }
      } else {
        if (this.player.optaExtraValueIndex) {
          var principles_index = JSON.parse(
            this.player.optaExtraValueIndex.principles_index
          );
          var principles_index_color_rank = JSON.parse(
            this.player.optaExtraValueIndex.principles_index_color_rank
          );
          for (var i in principles_index) {
            if (
              (this.player.ruolo_id == 6 && i != 12) ||
              (this.player.ruolo_id != 6 && i != 11)
            ) {
              var value = {
                id: this.player.id + "_" + i,
                order: this.principles_order[i],
                score: principles_index[i],
                value: principles_index[i],
                weight: 1,
                color: this.bg_opta[principles_index_color_rank[i]],
                label: this.principles_title[i],
                sigla: this.principles_title[i].substr(0, 3),
              };
              data.push(value);
            }
          }
        }
      }
      data.sort(function (a, b) {
        return a.order - b.order;
      });
      return data;
    },

    sorted_desc_player_data: function () {
      var desc_data = this.player_data
        .slice(0)
        .sort(function (a, b) {
          return b.score - a.score;
        })
        .slice(0, 4);
      while (desc_data.length < 4) {
        var value = {
          value: "N/D",
          color: "#FFFFFF",
          label: "?",
        };
        desc_data.push(value);
      }
      return desc_data;
    },
    sorted_asc_player_data: function () {
      var asc_data = this.player_data
        .slice(0)
        .sort(function (a, b) {
          return a.score - b.score;
        })
        .slice(0, 4);
      while (asc_data.length < 4) {
        var value = {
          value: "N/D",
          color: "#FFFFFF",
          label: "?",
        };
        asc_data.push(value);
      }
      return asc_data;
    },

    grad1_count: function () {
      if (this.player.giocatore && this.player.giocatore.osservato) {
        return this.player.giocatore.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 1
        ).length;
      }
      return 0;
    },

    grad2_count: function () {
      if (this.player.giocatore && this.player.giocatore.osservato) {
        return this.player.giocatore.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 2
        ).length;
      }
      return 0;
    },

    grad3_count: function () {
      if (this.player.giocatore && this.player.giocatore.osservato) {
        return this.player.giocatore.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 3
        ).length;
      }
      return 0;
    },

    grad4_count: function () {
      if (this.player.giocatore && this.player.giocatore.osservato) {
        return this.player.giocatore.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 4
        ).length;
      }
      return 0;
    },

    grad5_count: function () {
      if (this.player.giocatore && this.player.giocatore.osservato) {
        return this.player.giocatore.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 5
        ).length;
      }
      return 0;
    },
    opacity: function () {
      var count_grad = [
        this.grad1_count,
        this.grad2_count,
        this.grad3_count,
        this.grad4_count,
        this.grad5_count,
      ];
      var array_index = [0, 1, 2, 3, 4];
      var count = 0;

      var opacity = [
        { index: 0, grad: count_grad[0] },
        { index: 1, grad: count_grad[1] },
        { index: 2, grad: count_grad[2] },
        { index: 3, grad: count_grad[3] },
        { index: 4, grad: count_grad[4] },
      ];

      opacity.sort(function (a, b) {
        return b.grad > a.grad;
      });

      var array_index = [];
      for (var index in opacity) {
        array_index.push(opacity[index].index);
      }
      return array_index;
    },
    grad1_style: function () {
      if (this.grad1_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad2_style: function () {
      if (this.grad2_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad3_style: function () {
      if (this.grad3_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad4_style: function () {
      if (this.grad4_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad5_style: function () {
      if (this.grad5_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
  },

  methods: {
    getValueStat(skill_id) {
      if (this.player.stats) {
        for (var i in this.player.stats) {
          var item = this.player.stats[i];
          if (item.skill_id == skill_id) {
            return ("" + item.skill_value).includes(".")
              ? "" + Math.round(item.skill_value * 100) / 100
              : "" + item.skill_value;
          }
        }
      }
      return "N/D";
    },

    getClassByIndex(index) {
      if (index == 0 || index == 6) {
        return "";
      }
      if (index == 5) {
        return "p-0 py-2 text-center";
      }
      return "p-0 pt-2 text-center";
    },

    getBgStat(skill_id, value) {
      if (value != "N/D") {
        value = parseFloat(value);
        for (var i in this.skills) {
          if (this.skills[i].id == skill_id) {
            var range = null;
            var obj_range = this.skills[i].ranges.find(
              (item) =>
                item.cluster_id == this.player.cluster &&
                item.group == this.player.ruolo.macro
            );
            if (obj_range) {
              range = JSON.parse(obj_range.range);
            }
            if (range) {
              for (var x in range) {
                if (
                  range[x].from == range[x].to && this.skills[i].reverse
                    ? value >= range[x].from && value <= range[x].to
                    : value <= range[x].from && value >= range[x].to
                ) {
                  return this.bg_opta[x];
                }
                if (
                  range[x].from != range[x].to &&
                  ((x < range.length - 1 && this.skills[i].reverse
                    ? value >= range[x].from && value < range[x].to
                    : value <= range[x].from && value > range[x].to) ||
                    (x == range.length - 1 && this.skills[i].reverse
                      ? value >= range[x].from && value <= range[x].to
                      : value <= range[x].from && value >= range[x].to))
                ) {
                  return this.bg_opta[x];
                }
                if (!range[x].to && value <= range[x].from) {
                  return this.bg_opta[x];
                }
              }
            }
          }
        }
      }
      return "#FFFFFF";
    },

    /*  normalizza(skill_id, value) {
      var min = 15;
      var max = 100;
      for (var i in this.skills) {
        if (this.skills[i].id == skill_id) {
          var obj_range = this.skills[i].ranges.find(
            (item) =>
              item.cluster_id == this.player.cluster &&
              item.group == this.player.ruolo.macro
          );
          if (obj_range) {
            if (obj_range.max > obj_range.min) {
              return (
                min +
                ((value - obj_range.min) / (obj_range.max - obj_range.min)) *
                  (max - min)
              );
            }
          }
          return 100;
        }
      }
      return value;
    }, */

    normalizza(value, array_range) {
      var min = 15;
      var max = 100;

      var min_index = -1;
      var max_index = -1;
      for (var i in array_range) {
        var item = array_range[i];
        if (min_index == -1 || item < min_index) {
          min_index = item;
        }
        if (max_index == -1 || item > max_index) {
          max_index = item;
        }
      }
      var value_norm = 100;
      if (max_index > min_index) {
        value_norm =
          min + ((value - min_index) / (max_index - min_index)) * (max - min);
      }
      return value_norm;
    },

    addGiocatoreLista(id) {
      this.$emit("addGiocatoreLista", id);
    },

    delGiocatoreLista(id) {
      this.$emit("delGiocatoreLista", id);
    },
    editConfronto(player) {
      this.$emit("editConfronto", player);
    },

    getRankIndexMacro() {
      if (
        this.$store.state.extraValueIndex &&
        this.player.optaExtraValueIndex
      ) {
        this.$http
          .get(
            "/player/rank/extra/value/index/macro/" +
              this.player.optaExtraValueIndex.id
          )
          .then((response) => {
            this.players_index_rank = response.data;
            this.title_index_rank = "Extra Value Macro";
            this.$refs["rankIndexMacroModal" + this.player.id].show();
          });
      } else if (!this.$store.state.extraValueIndex && this.player.optaIndex) {
        this.$http
          .get("/player/rank/index/macro/" + this.player.optaIndex.id)
          .then((response) => {
            this.players_index_rank = response.data;
            this.title_index_rank = "Macro";
            this.$refs["rankIndexMacroModal" + this.player.id].show();
          });
      }
    },

    getRankIndexMicro() {
      if (
        this.$store.state.extraValueIndex &&
        this.player.optaExtraValueIndex
      ) {
        this.$http
          .get(
            "/player/rank/extra/value/index/micro/" +
              this.player.optaExtraValueIndex.id
          )
          .then((response) => {
            this.players_index_rank = response.data;
            this.title_index_rank = "Extra Value Micro";
            this.$refs["rankIndexMicroModal" + this.player.id].show();
          });
      } else if (!this.$store.state.extraValueIndex && this.player.optaIndex) {
        this.$http
          .get("/player/rank/index/micro/" + this.player.optaIndex.id)
          .then((response) => {
            this.players_index_rank = response.data;
            this.title_index_rank = "Micro";
            this.$refs["rankIndexMicroModal" + this.player.id].show();
          });
      }
    },
  },

  filters: {
    year: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("YYYY");
      }
      return "";
    },
    age: function (date) {
      var birthday = moment(date);
      if (birthday.isValid()) {
        return moment().diff(birthday, "years");
      }
      return "";
    },
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
