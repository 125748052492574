<template id="dashboard">
  <b-card class="m-1 h-100">
    <b-row align-v="center">
      <b-col cols="1" class="p-0">
        <div
          v-if="editable && !in_lista"
          class="plus_round"
          style="cursor: pointer; z-index: 1; padding-top: 1px"
          @click="addGiocatoreLista(player.id)"
        >
          <b-icon icon="plus" scale="2"></b-icon>
        </div>
        <div
          v-if="editable && in_lista"
          class="minus_round"
          style="cursor: pointer; z-index: 1; padding-top: 1px"
          @click="delGiocatoreLista(player.id)"
        >
          <b-icon icon="dash" scale="2"></b-icon>
        </div>
      </b-col>
      <b-col class="p-0" align-self="center" cols="5">
        <router-link
          :to="{
            name: 'ScoutingEvalPlayer',
            params: { id: player.id },
          }"
          target="_blank"
          style="cursor: pointer"
        >
          <avatar :avatar="player.avatar_url" :id="'no_opta' + player.id" />
        </router-link>
      </b-col>
      <b-col :cols="auth.isUserEnable('opta') ? '5' : '6'">
        <b-popover
          v-if="
            player &&
            player.osservato &&
            player.osservato.valutazioni.length > 0
          "
          :target="'popover-target-' + player.id"
          triggers="hover"
          placement="top"
        >
          <b-row
            align-v="center"
            v-for="val in player.osservato.valutazioni"
            :key="val.id"
          >
            <b-col>
              {{ val.created_at | formatDate }}
            </b-col>
            <b-col v-if="auth.isAdmin() || auth.isOsservCapo()">
              {{ val.utente.cognome }}
            </b-col>
            <b-col>
              <div class="py-1">
                <grad1 class="grad" v-if="val.gradimento_id == 1" />
                <grad2 class="grad" v-if="val.gradimento_id == 2" />
                <grad3 class="grad" v-if="val.gradimento_id == 3" />
                <grad4 class="grad" v-if="val.gradimento_id == 4" />
                <grad5 class="grad" v-if="val.gradimento_id == 5" />
              </div>
            </b-col>
          </b-row>
        </b-popover>
        <div
          :id="'popover-target-' + player.id"
          v-if="auth.isUserEnable('opta')"
        >
          <b-row>
            <b-col class="p-0 text-center"><grad1 class="grad" /></b-col>
            <b-col class="p-0 text-center"><grad2 class="grad" /></b-col>
            <b-col class="p-0 text-center"><grad3 class="grad" /></b-col>
            <b-col class="p-0 text-center"><grad4 class="grad" /></b-col>
            <b-col class="p-0 text-center"><grad5 class="grad" /></b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col class="p-0 text-center"
              ><b-badge variant="bg-1" :style="grad1_style">{{
                grad1_count
              }}</b-badge>
            </b-col>
            <b-col class="p-0 text-center"
              ><b-badge variant="bg-2" :style="grad2_style">{{
                grad2_count
              }}</b-badge>
            </b-col>
            <b-col class="p-0 text-center"
              ><b-badge variant="bg-3" :style="grad3_style">{{
                grad3_count
              }}</b-badge></b-col
            >
            <b-col class="p-0 text-center"
              ><b-badge variant="bg-4" :style="grad4_style">{{
                grad4_count
              }}</b-badge></b-col
            >
            <b-col class="p-0 text-center"
              ><b-badge variant="bg-5" :style="grad5_style">{{
                grad5_count
              }}</b-badge></b-col
            >
          </b-row>
        </div>
        <b-row class="mt-1 mb-1">
          <b-col cols="12" style="font-size: 0.8rem; font-weight: bold">
            <div
              :title="player.nome + ' ' + player.cognome"
              style="
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ player.nome }}
              {{ player.cognome }}
            </div>
          </b-col>
          <b-col cols="12" style="font-size: 0.75rem; min-height: 18px"
            >{{ player.data_nascita | year }} ({{
              player.data_nascita | age
            }})</b-col
          >
          <b-col cols="12" style="font-size: 0.75rem; min-height: 18px">
            {{ ruolo_ideale ? ruolo_ideale.ruolo : "" }}
          </b-col>
          <b-col cols="12" style="font-size: 0.75rem; min-height: 18px">{{
            player.squadra_militante ? player.squadra_militante.name : ""
          }}</b-col>
          <b-col cols="12">
            <flag
              :country="player.paese_nascita ? player.paese_nascita.sigla : ''"
              :size="16"
              class="mr-2"
              title="Country of birth"
            ></flag>
            <flag
              :country="
                player.passaporti && player.passaporti.length > 0
                  ? player.passaporti[0].sigla
                  : ''
              "
              :size="16"
              title="Passport"
            ></flag
          ></b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Auth from "@/lib/auth.js";
import moment from "moment";
import Avatar from "@/components/Avatar.vue";
import Flag from "@/components/Flag.vue";
import grad1 from "@/assets/images/grad1.svg";
import grad2 from "@/assets/images/grad2.svg";
import grad3 from "@/assets/images/grad3.svg";
import grad4 from "@/assets/images/grad4.svg";
import grad5 from "@/assets/images/grad5.svg";
export default {
  props: {
    player: {
      type: Object,
      required: true,
    },
    in_lista: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      opacity_value: [1, 0.8, 0.7, 0.6, 0.5],
    };
  },

  components: {
    Avatar,
    grad1,
    grad2,
    grad3,
    grad4,
    grad5,
    Flag,
  },

  created: function () {},

  computed: {
    auth: function () {
      return Auth;
    },

    user: function () {
      return Auth.user;
    },

    ruolo_ideale: function () {
      return this.player &&
        this.player.ruolo_ideale &&
        this.player.ruolo_ideale.length > 0
        ? this.player.ruolo_ideale[0]
        : null;
    },

    grad1_count: function () {
      if (this.player && this.player.osservato) {
        return this.player.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 1
        ).length;
      }
      return 0;
    },

    grad2_count: function () {
      if (this.player && this.player.osservato) {
        return this.player.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 2
        ).length;
      }
      return 0;
    },

    grad3_count: function () {
      if (this.player && this.player.osservato) {
        return this.player.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 3
        ).length;
      }
      return 0;
    },

    grad4_count: function () {
      if (this.player && this.player.osservato) {
        return this.player.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 4
        ).length;
      }
      return 0;
    },

    grad5_count: function () {
      if (this.player && this.player.osservato) {
        return this.player.osservato.valutazioni.filter(
          (elem) => elem.gradimento_id === 5
        ).length;
      }
      return 0;
    },
    opacity: function () {
      var count_grad = [
        this.grad1_count,
        this.grad2_count,
        this.grad3_count,
        this.grad4_count,
        this.grad5_count,
      ];
      var array_index = [0, 1, 2, 3, 4];
      var count = 0;

      var opacity = [
        { index: 0, grad: count_grad[0] },
        { index: 1, grad: count_grad[1] },
        { index: 2, grad: count_grad[2] },
        { index: 3, grad: count_grad[3] },
        { index: 4, grad: count_grad[4] },
      ];

      opacity.sort(function (a, b) {
        return b.grad > a.grad;
      });

      var array_index = [];
      for (var index in opacity) {
        array_index.push(opacity[index].index);
      }
      return array_index;
    },
    grad1_style: function () {
      if (this.grad1_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad2_style: function () {
      if (this.grad2_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad3_style: function () {
      if (this.grad3_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad4_style: function () {
      if (this.grad4_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
    grad5_style: function () {
      if (this.grad5_count > 0) {
        return {
          opacity: this.opacity_value[this.opacity.indexOf(0)],
        };
      } else {
        return {
          opacity: "0.3",
        };
      }
    },
  },

  methods: {
    addGiocatoreLista(id) {
      this.$emit("addGiocatoreLista", id);
    },

    delGiocatoreLista(id) {
      this.$emit("delGiocatoreLista", id);
    },
  },

  filters: {
    year: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("YYYY");
      }
      return "";
    },
    age: function (date) {
      var birthday = moment(date);
      if (birthday.isValid()) {
        return moment().diff(birthday, "years");
      }
      return "";
    },
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
